import { Toast } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import React from "react";

export default function Snackbar({ text, style, show, setShow }) {
  return (
    <Toast 
        bg={style} 
        className="toast" 
        show={show} 
        delay={3000}
        onClose={() => setShow(false)}
        animation={true} 
        autohide>
      <Toast.Body className="toast-body" closeButton={true}>{text}</Toast.Body>
      <div>
        <MdClose size={24} className="toast-close-icon" />
      </div>
    </Toast>
  );
}
