import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Save from "../services/confirmPresenceService";
import Snackbar from "./Snackbar";

function ConfirmPresence() {
  const [formData, setFormData] = useState({
    nome: null,
    email: null,
    contato: null,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShowToast] = useState(false);
  const [successSave, setSuccessSave] = useState(false);

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFormSubmit = async () => {
    setIsLoading(true);
    let resultCode = await Save(formData);
    setShowToast(true);
    setIsLoading(false);
    if (resultCode == null || resultCode !== 201) {
      setSuccessSave(false);
      return;
    }
    setSuccessSave(true);
    setFormData({ nome: "", email: "", contato: "" });
  };

  return (
    <Form className="teste">
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Nome Completo</Form.Label>
        <Form.Control
          type="input"
          placeholder="Insira seu nome completo"
          name="nome"
          value={formData.nome}
          onChange={(e) => handleFormChange(e)}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>E-mail</Form.Label>
        <Form.Control
          type="email"
          placeholder="exemplo@email.com"
          name="email"
          value={formData.email}
          onChange={handleFormChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Telefone para contato</Form.Label>
        <Form.Control
          mask="(00) 00000-0000"
          placeholder="(85) 99999-9999"
          name="contato"
          value={formData.contato}
          onChange={handleFormChange}
        />
      </Form.Group>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          variant="outline-primary"
          className="form-submit-btn"
          disabled={isLoading}
          onClick={handleFormSubmit}
        >
          Confirmar Presença
        </Button>
      </div>
      {show && (
        <Snackbar
          text={
            successSave
              ? "Presença confirmada com sucesso"
              : "Algo deu errado, tente novamente."
          }
          style={successSave ? "success" : "danger"}
          show={show}
          setShow={setShowToast}
        />
      )}
    </Form>
  );
}

export default ConfirmPresence;
